import React, { memo } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Button, VStack, Link } from '@chakra-ui/react';

import ProjectCard from '~entities/ProjectCard';
import { ProjectsLayout } from 'shared/ProjectsLayout';
import BlockWrapper from 'shared/BlockWrapper';

import { primaryProjects } from 'constants/projects/primary';
import { primaryLayout } from 'constants/projects/projectsLayoutConfigs';

const Projects = ({
  HeaderRender = null,
  projectsData = primaryProjects,
  projectsConfig = primaryLayout,
  projectsProps = null,
  onButtonClick = null,
  buttonText = 'Все проекты',
  buttonLink = '/our-projects',
  isButtonVisible = true,
}) => (
  <VStack w="full" gap={{ laptop: '40px', tablet: '24px', mobile: '20px' }}>
    <BlockWrapper>
      {HeaderRender}

      <ProjectsLayout
        projects={projectsData}
        config={projectsConfig}
        ElementComponent={ProjectCard}
        {...projectsProps}
      />
    </BlockWrapper>

    {isButtonVisible &&
      (onButtonClick ? (
        <Button size="secondary" w="full" onClick={onButtonClick}>
          {buttonText}
        </Button>
      ) : (
        <Link as={GatsbyLink} to={buttonLink} w="full">
          <Button size="secondary" w="full">
            {buttonText}
          </Button>
        </Link>
      ))}
  </VStack>
);

export default memo(Projects);
